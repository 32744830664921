<template>
  <v-container class="altback fill-height" fluid>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="3">
          <v-card color="surface" class="rounded-lg">
            <v-sheet height="64" color="primary" />

            <div
              class="d-flex flex-column align-center justify-center mb-6"
              style="margin-top: -32px"
            >
              <v-avatar size="128" class="mb-4">
                <v-img src="@/assets/utils/profile.png" />
              </v-avatar>

              <span class="text-h6">
                {{ user.firstName }} {{ user.lastName }}
              </span>

              <span class="text-body-1 subtext--text">
                {{ user.email }}
              </span>

              <span class="text-body-1 subtext--text">
                {{ user.phone }}
              </span>
            </div>

            <v-list class="transparent" nav>
              <router-link
                v-for="(item, i) in routes"
                v-slot="{ navigate, isExactActive }"
                :key="i"
                :to="item.path"
                custom
              >
                <v-list-item
                  :class="`${!isExactActive || 'border'} rounded-l-0 `"
                  @click="navigate"
                >
                  <v-list-item-icon class="ml-2 mr-4">
                    <v-icon :color="isExactActive ? 'primary' : 'overtext'">
                      {{ isExactActive ? item.activeIcon : item.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-title :class="!isExactActive || 'primary--text'">
                    <span class="text-body-1">
                      {{ item.label }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </router-link>

              <v-list-item @click="exit()">
                <v-list-item-icon class="ml-2 mr-4">
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="12" md="9">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      routes: [
        {
          icon: "mdi-account-outline",
          activeIcon: "mdi-account",
          label: "Dados Pessoais",
          path: "/user",
        },
        {
          icon: "mdi-clock-outline",
          activeIcon: "mdi-clock",
          label: "Meus Agendamentos",
          path: "/user/schedules",
        },
      ],
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    ...mapActions(["setSignOut"]),

    exit() {
      this.setSignOut();
      this.$router.push({ path: "/home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-left: 4px solid #ff6700;
}
</style>